import React, {useEffect, useState} from "react";

import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";


function ProductNamePrice(props) {
    const [data, setData] = useState({title: null, oldPrice: 0, description: null});
    useEffect(() => {
        if (props.variantId && props.product.product.variants[0].price>0) {
            const productVariant = props.product.product.variants.filter(variant => variant.id === parseInt(props.variantId, 10));
            // console.log(productVariant);
            if(productVariant.length){
            if (productVariant[0].compare_at_price !== productVariant[0].price) {
                setData({
                    title: props.product.product.title,
                    price: productVariant[0].price + ` kr`,
                    oldPrice: productVariant[0].compare_at_price + ` kr`,
                })
            } else {
                setData({
                    title: props.product.product.title,
                    price: props.product.product.variants[0].price + ` kr`,
                    oldPrice: 0,
                })
            }}
        } else {
            if (props.product.product.variants && props.product.product.variants.length) {
                if (props.product.product.variants[0].compare_at_price &&
                    props.product.product.variants[0].compare_at_price !== props.product.product.variants[0].price) {
                    setData({
                        title: props.product.product.title,
                        price: props.product.product.variants[0].price + ` kr`,
                        oldPrice: props.product.product.variants[0].compare_at_price + ` kr`,
                    })
                } else {
                    setData({
                        title: props.product.product.title,
                        price: props.product.product.variants[0].price + ` kr`,
                        oldPrice: 0,
                    })
                }
            }
        }
    }, [props.product,props.variantId]);
    // console.log(props.product);
    return (
        <div className={props.mobile? 'mobile-only' : 'desktop-only'}>
            <div id={'product-brand-name'}>
                {props.product.product.brand && <img alt="" src={props.product.product.brand.logo} width={props.mobile? 60:50}/>}
            </div>
            <h1>{data.title || <Skeleton/>}</h1>
            <div className={'product-price'}>
                {props.product.product.variants[0].price && <span className={'current-price'}>{data.price}</span> ||
                <Skeleton width={70}/>}
                <span className={'old-price'}>{data.oldPrice !== 0 ? data.oldPrice : ''}</span>
                <span className="info-price" data-tip data-for={`bonusPrice-${props.mobile?2:1}`}><img alt={""}
                    src="https://img.icons8.com/ios/50/FF5B23/info--v1.png" className="info-bonus"/>Pluss 10-30% bonus!</span>
                <ReactTooltip id={`bonusPrice-${props.mobile?2:1}`} globalEventOff={'click'} type='light' place="bottom" multiline={true}
                              event='click' effect='solid' offset={{top: 0, left: props.mobile? -10 : 10}} border={true} clickable={true}>
                    <span>Som medlem av Anton Club får du bonus på alle ordinære priser når du handler hos Anton Sport, med 10% fra første krone!<br/>
                        Bonusen du samler opp kan du bruke som betalingsmiddel i alle våre butikker og i nettbutikken. 1 bonuspoeng = 1 krone.<br/>
                        Les mer om Anton club og vår bonusstige her: <a href={'https://www.antonsport.no/bli-medlem'}
                                                                        target={'_blank'}>https://www.antonsport.no/bli-medlem</a>.</span>
                </ReactTooltip>
            </div>
        </div>


    );
}


export default ProductNamePrice;
