export function getFilteredProductImages(vendorId, productId, variantId, product) {
    // console.log('console:', variantId);
    // productId may be not consistent with product.id for barbershop, so mapping down there has to be performed
    // const mapBarbershopVariants = [{
    //     oldId: "5fc4d9a74cedfd000dc2e6bf",
    //     newId: "5386246727",
    //     name: "Introsett til tradisjonell barbering",
    //     variantId: 32115078856809
    // },
    //     {
    //         oldId: "5fc4da524cedfd000dc2e6c0",
    //         newId: "4242984455",
    //         name: "R89",
    //         variantId: 0
    //     },
    //     {
    //         oldId: "5fc4d9cf52faff000e211307",
    //         newId: "1490082398313",
    //         name: "Barbersett for henne",
    //         variantId: 32115541409897
    //     }];

    // for other vendors than barbershop (antonsport for now) only one variant should be returned from api
    // const variant = vendorId === "barbershop" ?
    //     mapBarbershopVariants.filter(m => m.oldId === productId)[0].variantId : product.variants[0].id;
    // if (variant !== 0) {
    //     return product.images.filter(img => img.variant_ids.includes(variant) || img.variant_ids.length === 0);
    // } else {
    if (variantId) {
        const imgs= product.images.filter(img => img.variant_ids.includes(parseInt(variantId, 10)));
        if(imgs.length){
            return imgs;
        } else {
            return product.images;
        }
    } else {
        return product.images;
    }

}