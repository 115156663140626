import React, {useEffect, useState} from "react";

function ProductDescription(props) {
    // const dev = process.env.NODE_ENV !== 'production';
    const [data, setData] = useState({title: null, oldPrice: 0, description: null, fullDesc: null});
    // const [open, setOpen] = useState(true);
    const [opened, setOpened] = useState(false);
    const [openedFull, setOpenedFull] = useState(false);
    const [lines, setLines] = useState(0);
    const [linesFull, setLinesFull] = useState(0);
    useEffect(() => {
        if (props.product.product.variants && props.product.product.variants.length) {

            const splitedDesc = !!props.product.product.body_html ? props.product.product.body_html
                .replace(/\n\n---\n\n/ig, '\n---\n')
                .replace(/\n\n\n/ig, '\n\n')
                .replace(/--\n\n/ig, '\n')
                .replace('- **Mål S/M:**', '<strong>Mål S/M:</strong>')
                .replace('- **Mål L/XL:**', '<strong>Mål L/XL:</strong>')
                .replace('- **Volum:**', '<strong>Volum:</strong>')
                .replace('- **Materialer:**', '<strong>Materialer:</strong>')
                .replace('- **Mål:**', '<strong>Mål:</strong>')
                .replace('- **VOLUM:**', '<strong>Volum:</strong>')
                .replace('- **Størrelse:**', '<strong>Størrelse:</strong>')
                .replace('- **STØRRELSE:**', '<strong>Størrelse:</strong>')
                .replace('- **STØRRELSE:**', '<strong>Størrelse:</strong>')
                .replace('- **Materiale:**', '<strong>Materiale:</strong>')
                .replace('- **Vekt:**', '<strong>Vekt:</strong>')
                .replace('- **VEKT:**', '<strong>Vekt:</strong>')
                .split('---') : ['', ''];
            if (splitedDesc.length) {
                if (splitedDesc[1]) {
                    const nr = splitedDesc[1].split("\n");
                    setLines(nr.length);
                }
            }
            if (splitedDesc.length) {
                if (splitedDesc[0]) {
                    const nr2 = splitedDesc[0].split("\n");
                    setLinesFull(nr2.length);
                }
            }
            setData({
                title: props.product.product.title,
                price: props.product.product.variants[0].price + ` kr`,
                oldPrice: props.product.product.variants[0].compare_at_price + ` kr`,
                description: splitedDesc[1],
                fullDesc: splitedDesc[0]
            })

        }
    }, [props.product]);

    const openMore = () => {
        setOpened(true);
    }
    const openMoreFull = () => {
        setOpenedFull(true);
    }
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
    console.log('>>>',data.description);
    return (
        <div>
            {(typeof data.description !== 'undefined' && props.type === 'short')  && <div
                className={`description-prod ${openedFull ? 'open' : ''} ${opened ? 'open' : ''} ${lines > 4 ? 'more' : ''} ${props.type === 'short' ? '' : 'full'}`}>
                <div dangerouslySetInnerHTML={{
                    __html: props.type === 'short' ? nl2br(data.description, false) : data.fullDesc
                }}>
                </div>
                {props.type === 'short' && !opened && lines > 4 &&
                <span className={'readMore'} onClick={openMore}>Les mer</span>}
                {props.type !== 'short' && !openedFull && linesFull > 2 &&
                <span className={'readMore'} onClick={openMoreFull}>Les mer</span>}
            </div>}
            {(props.type !== 'short')  && <div
                className={`description-prod ${openedFull ? 'open' : ''} ${opened ? 'open' : ''} ${lines > 4 ? 'more' : ''} ${props.type === 'short' ? '' : 'full'}`}>
                <div dangerouslySetInnerHTML={{
                    __html: props.type === 'short' ? nl2br(data.description, false) : data.fullDesc
                }}>
                </div>
                {props.type === 'short' && !opened && lines > 4 &&
                <span className={'readMore'} onClick={openMore}>Les mer</span>}
                {props.type !== 'short' && !openedFull && linesFull > 2 &&
                <span className={'readMore'} onClick={openMoreFull}>Les mer</span>}
            </div>}
        </div>

    );
}


export default ProductDescription;
